/* eslint semi: ["error", "never"]*/
/* eslint key-spacing: ["error", { "afterColon": true, "align": "value" }] */

import Error                      from '@engine/components/presentational/base/error'
import Icon                       from '@engine/components/presentational/base/icon'
import PropTypes                  from 'prop-types'
import React                      from 'react'
import { connect }                from 'react-redux'
import { fetchServiceStatusData } from '@app/components/actions/creators/service-status'

const SYSTEM_STATUS_UPDATE_INTERVAL = 15000

class ServiceStatus extends React.Component {

  /**
   * Component life cycle
   */

  componentDidMount() {
    this.fetchData()
    this.interval = setInterval(() => {
      this.fetchData()
    }, SYSTEM_STATUS_UPDATE_INTERVAL)
  }

  componentWillUnmount() {
    this.stopFetchingData()
  }

  /**
   * Private methods
   */

  fetchData() {
    this.props.fetchServiceStatusData(this.props.url)
  }

  stopFetchingData() {
    clearInterval(this.interval)
  }

  /**
   * Computed properties
   */

  get status() {
    return this.props.serviceStatus.status
  }

  get hasError() {
    return this.props.serviceStatus.hasError
  }

  get errorMessage() {
    return this.props.serviceStatus.error
  }

  /**
   * Render component
   */

  render() {

    if (this.hasError) {
      return <Error>{this.errorMessage}</Error>
    }

    return (
      <span className={this.status ? 'text-success' : 'text-danger'}>
        <Icon icon={this.status ? 'ok' : 'exclamation-sign'} />
      </span>
    )
  }
}

ServiceStatus.propTypes = {
  fetchServiceStatusData: PropTypes.func.isRequired,
  url:                    PropTypes.string.isRequired,
  serviceStatus:          PropTypes.object.isRequired
}

export default connect(
  (state) => ({ serviceStatus: state.serviceStatus }),
  { fetchServiceStatusData }
)(ServiceStatus)
