/* eslint semi: ["error", "never"]*/
/* eslint key-spacing: ["error", { "afterColon": true, "align": "value" }] */

import {
  DECLINED_REASONS_DATA_FAIL,
  DECLINED_REASONS_DATA_RECEIVE,
  DECLINED_REASONS_DATA_REQUEST
} from '@app/components/actions/action-types'

/**
 * Initial state
 */

const INITIAL_STATE = {
  reports:  null,
  hasError: false,
  error:    null
}

/**
 * Reducer
 */

function declinedReasonsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
  case DECLINED_REASONS_DATA_REQUEST:
    return { ...state, hasError: false }
  case DECLINED_REASONS_DATA_RECEIVE:
    return { ...state, reports: action.data.reports }
  case DECLINED_REASONS_DATA_FAIL:
    return { ...state, hasError: true, error: action.error.message }
  default:
    return state
  }
}

export default declinedReasonsReducer
