/* eslint-disable no-inline-comments */
/* eslint semi: ["error", "never"]*/

import React, { Component } from 'react'
import Button               from '@engine/components/presentational/base/button'
import Error                from '@engine/components/presentational/base/error'
import Icon                 from '@engine/components/presentational/base/icon'
import IssuingLoadOperationsPresenter from
  '@app/components/presentational/issuing-load-operations-presenter'
import Panel                       from '@engine/components/presentational/base/panel'
import PropTypes                   from 'prop-types'
import Spinner                     from '@engine/components/presentational/base/spinner'
import Table                       from '@engine/components/presentational/base/table'
import { connect }                 from 'react-redux'
import { fetchLoadOperationsData } from '@app/components/actions/creators/issuing-load-operations'

class IssuingLoadOperations extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpened: false
    }

    this.localeKey = 'issuing.load_operations'

    this.classNames = {
      button: 'btn btn-primary btn-xs panel-load',
      table: 'table table-striped table-bordered table-hover card-load-operations'
    }

    this.locales = {
      paneTitle: I18n.t(`${this.localeKey}.title`),
      button: {
        show: I18n.t(`${this.localeKey}.show`),
        hide: I18n.t(`${this.localeKey}.hide`)
      },
      tableColumns: [
        I18n.t(`${this.localeKey}.payment_id`),
        I18n.t(`${this.localeKey}.status`),
        I18n.t(`${this.localeKey}.amount`),
        I18n.t(`${this.localeKey}.created_at`)
      ]
    }
  }

  togglePanel = () => {
    if (!this.props.issuingLoadOperations.isFetched) {
      this.props.fetchLoadOperationsData(this.props.cardLoadOperationLink)
    }
    this.setState({ isOpened: !this.state.isOpened })
  }

  render() {
    const { issuingLoadOperations } = this.props

    return (
      <div className='row'>
        <div className='col-lg-12'>
          <Panel.Container>
            <Panel.Header>
              <Icon icon='retweet' />

              <span> {this.locales.paneTitle}</span>
              <div className='pull-right'>
                <Button
                  className={this.classNames.button}
                  type='primary'
                  size='xs'
                  onClick={this.togglePanel}
                >
                  {this.state.isOpened ? this.locales.button.hide : this.locales.button.show}
                </Button>
              </div>
            </Panel.Header>
            {this.state.isOpened &&
              <Panel.Body>
                {issuingLoadOperations.isFetching &&
                  <Spinner position='center' color='refined-blue' />
                }
                {(issuingLoadOperations.isFetched && issuingLoadOperations.hasError) &&
                  <Error>{issuingLoadOperations.error}</Error>
                }
                {(issuingLoadOperations.isFetched && !issuingLoadOperations.hasError) &&
                  <div className='table-responsive'>
                    <Table.Container className={this.classNames.table}
                    >
                      <Table.Head columns={this.locales.tableColumns}/>
                      <Table.Body>
                        <IssuingLoadOperationsPresenter
                          issuingLoadOperations={issuingLoadOperations.data}
                        />
                      </Table.Body>
                    </Table.Container>
                  </div>
                }
              </Panel.Body>
            }
          </Panel.Container>
        </div>
      </div>
    )
  }
}

IssuingLoadOperations.propTypes = {
  cardLoadOperationLink: PropTypes.string.isRequired,
  fetchLoadOperationsData: PropTypes.func.isRequired,
  issuingLoadOperations: PropTypes.object.isRequired
}

export default connect(
  (state) => ({ issuingLoadOperations: state.issuingLoadOperations }),
  { fetchLoadOperationsData }
)(IssuingLoadOperations)
