/**
 * Vendor jQuery Plugins
 */
require('jquery-ujs');

// jQuery.isFunc() has been deprecated. Do not import tooltip & popover as they rely on it.
require('bootstrap-sass/assets/javascripts/bootstrap/affix');
require('bootstrap-sass/assets/javascripts/bootstrap/alert');
require('bootstrap-sass/assets/javascripts/bootstrap/button');
require('bootstrap-sass/assets/javascripts/bootstrap/carousel');
require('bootstrap-sass/assets/javascripts/bootstrap/collapse');
require('bootstrap-sass/assets/javascripts/bootstrap/dropdown');
require('bootstrap-sass/assets/javascripts/bootstrap/modal');
require('bootstrap-sass/assets/javascripts/bootstrap/scrollspy');
require('bootstrap-sass/assets/javascripts/bootstrap/tab');
require('bootstrap-sass/assets/javascripts/bootstrap/transition');

require('chosen-js');
require('jquery-highlight');
require('eonasdan-bootstrap-datetimepicker');

/**
 *  This file is automatically compiled by Webpack, along with any other files
 *  present in this directory. You're encouraged to place your actual application logic in
 *  a relevant structure within app/javascript and only use these pack files to reference
 *  that code so it'll be compiled.
 *
 *  To reference this file, add javascript_pack_tag 'application' to the appropriate
 *  layout file, like app/views/layouts/application.slim
 */
import * as DatePickerArchived from '@app/features/datepicker_search_archived';

import DependentDropdowns from '@app/features/dependent_dropdowns_extended';
import Engine from '@engine/engine';
import SelectizeWithDropdown from '@app/features/selectize_with_dropdown';

// eslint-disable-next-line sort-imports
import AdminConsoleReact from '@app/react.js';


Engine.React.initialize(function() {

  /**
   * React Application Components
   */
  AdminConsoleReact.initialize();
});

Engine.Legacy.initialize(function() {

  /* Dropdowns for Reseller, Merchant, Affiliate User Owner */
  DependentDropdowns.attachDependentDropdowns();

  // Datepickers
  DatePickerArchived.attachDatePicker();


  // Dropdown Tag selectize
  SelectizeWithDropdown.applyTagSelectize();
  SelectizeWithDropdown.listenForLinkClick();

  // This comment will change the hash of the application.js (Remove after test deploy)
});
