/* eslint semi: ["error", "never"]*/
/* eslint key-spacing: ["error", { "afterColon": true, "align": "value" }] */

import 'cross-fetch/polyfill'
import * as ACTION_TYPES from '@app/components/actions/action-types'

/**
 * Action Creators for Declined Reasons
 */

export const requestDeclinedReasonsData = () =>
  ({ type: ACTION_TYPES.DECLINED_REASONS_DATA_REQUEST })
export const receivedDeclinedReasonsData = (data) =>
  ({ type: ACTION_TYPES.DECLINED_REASONS_DATA_RECEIVE,
     data: data })
export const failDeclinedReasonsData = (error) =>
  ({ type:  ACTION_TYPES.DECLINED_REASONS_DATA_FAIL,
     error: error })

export function fetchDeclinedReasonsData(url) {

  return function(dispatch) {
    dispatch(requestDeclinedReasonsData())

    return fetch(url)
      .then((response) => response.json())
      .then((json) => dispatch(receivedDeclinedReasonsData(json)))
      .catch((error) => dispatch(failDeclinedReasonsData(error)))
  }
}
