/* eslint no-magic-numbers: "off" */
import {
  DAY_START_TIME,
  FORMAT,
  clearInputs
} from '@engine/features/datepicker_search';

import { exists } from '@engine/lib/utility/exists';

const DAYS_LIMIT = 7;

function getDateInputs() {
  const from = $('.archived_data[id$="from"]');
  const to = $('.archived_data[id$="to"]');

  return { from, to };
}

export function attachDatePicker() {
  if (!exists($('.archived_data'))) {
    return;
  }

  const from = getDateInputs().from;
  const to = getDateInputs().to;
  const maxDateEndTime = moment();

  function initArchivedDataDateTimePicker() {
    moment.updateLocale(I18n.locale, {
      week: { dow: 1 }
    });

    var defaultOptions = {
      format: FORMAT,
      sideBySide: true,
      locale: moment.locale(I18n.locale),
      useCurrent: 'day',
      defaultDate: moment().startOf('day')
    };

    var $time_fields = $('input.archived_data[id$="from"], input.archived_data[id$="to"]');

    if ($time_fields.length === 0) {
      return;
    }

    $time_fields.datetimepicker(defaultOptions);

    $.each(from, function(key, el) {
      $(el).data('DateTimePicker').maxDate(maxDateEndTime);
    });

    $.each(to, function(key, el) {
      $(el).data('DateTimePicker').maxDate(maxDateEndTime);
    });
  }

  function syncTo(minDate, from_date) {
    var dateType = from_date.id.slice(0, -4);
    var to_date = $('#' + dateType + 'to').data('DateTimePicker');
    var range = moment().diff(minDate, 'days');

    var limit = moment().subtract(range - DAYS_LIMIT, 'days');

    var maxDate = range <= DAYS_LIMIT ? moment() : limit;

    to_date.minDate(minDate).maxDate(maxDate.set(DAY_START_TIME));

    var currentDate = to_date.date();

    if (!currentDate) {
      return;
    }

    currentDate.set(DAY_START_TIME);
    to_date.date(currentDate).viewDate(currentDate);
  }

  function syncFrom(maxDate, to_date) {
    var dateType = to_date.id.slice(0, -2);
    var from_date = $('#' + dateType + 'from');
    var minDate = moment(maxDate).subtract(DAYS_LIMIT, 'days').set(DAY_START_TIME);

    from_date.data('DateTimePicker').maxDate(maxDate).minDate(minDate);
  }

  initArchivedDataDateTimePicker();
  clearInputs(from, to);

  to.on('dp.change', function(e) {
    if (e.date) {
      syncFrom(e.date, this);
    }
  });

  from.on('dp.change', function(e) {
    if (e.date) {
      syncTo(e.date, this);
    }
  });

}
