/* eslint semi:            ["error", "never"]*/
/* eslint arrow-parens:    ["error", "as-needed"]*/
/* eslint key-spacing:     ["error", { "afterColon": true, "align": "value" }] */
/* eslint no-multi-spaces: ["error", { exceptions: { "ImportDeclaration": true,
                                       "VariableDeclarator": true  } }] */

import { resetFormState,
  saveTestData }     from '@app/components/actions/creators/test-data-form'
import Button        from '@engine/components/presentational/base/button'
import { Component } from 'react'
import Error         from '@engine/components/presentational/base/error'
import Loader        from '@engine/components/presentational/base/loader'
import NumberField   from '@engine/components/presentational/base/number-field'
import Panel         from '@engine/components/presentational/base/panel'
import PropTypes     from 'prop-types'
import SelectField   from '@engine/components/presentational/base/select-field'
import { connect }   from 'react-redux'

const FRAUD_CHARGEBACK = 'chargeback'
const FRAUD_INTERCHANGE_REJECT = 'interchange_reject'

class TestDataForm extends Component {

  constructor(props) {
    super(props)

    this.state = {
      transactionId: '',
      fraudDataType: '',
      itemType:      '',
      rejectStatus:  ''
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isSaved && this.props.isSaved) {
      this.props.resetFormState()
    }
  }

  render() {
    const { transactionId, fraudDataType, itemType, rejectStatus } = this.state
    const { fraudDataTypeOptions,
            chargeBackItemTypeOptions,
            interchangeRejectStatusOptions
    } = this.props

    return (
      <div className='row'>
        <div className='col-md-6 col-lg-7'>

          {this.errorMessage()}

          <div className='form-horizontal'>
            <Panel.Container>
              <Panel.Body>
                <NumberField
                  label={I18n.t('object.attributes.payment_transaction_id')}
                  name='transactionId'
                  value={transactionId ? Number(transactionId) : null}
                  onChange={(value, name) => this.handleInputChange(name, value)}
                  fieldWidth='col-sm-12'
                />

                <SelectField
                  label={I18n.t('javascript.forms.test_data.fraud_data_type')}
                  name='fraudDataType'
                  value={fraudDataType}
                  options={fraudDataTypeOptions}
                  onChange={value => this.handleInputChange('fraudDataType', value)}
                  fieldWidth='col-sm-12'
                />

                {this.state.fraudDataType === FRAUD_CHARGEBACK &&
                  <SelectField
                    label={I18n.t('javascript.forms.test_data.chargeback_item_type')}
                    name='itemType'
                    value={itemType}
                    options={chargeBackItemTypeOptions}
                    onChange={value => this.handleInputChange('itemType', value)}
                    fieldWidth='col-sm-12'
                  />
                }

                {this.state.fraudDataType === FRAUD_INTERCHANGE_REJECT &&
                  <SelectField
                    label={I18n.t('javascript.forms.test_data.interchange_reject_statuses')}
                    name='rejectStatus'
                    value={rejectStatus}
                    options={interchangeRejectStatusOptions}
                    onChange={value => this.handleInputChange('rejectStatus', value)}
                    fieldWidth='col-sm-12'
                  />
                }
              </Panel.Body>
              <Panel.Footer>
                <div className='row'>
                  <div className='col-lg-4 col-lg-offset-4'>
                    {!this.props.isSaving && !this.props.isSaved &&
                      <Button
                        type='success'
                        className='btn-block'
                        onClick={this.handleSubmit}
                      >
                        {I18n.t('actions.default.generate')}
                      </Button>
                    }
                    {this.props.isSaving &&
                      <Loader
                        position='horizontal-center'
                        color='refined-blue'
                      />
                    }

                    {this.props.isSaved && !this.props.hasError &&
                      <Loader
                        position='horizontal-center'
                        color='refined-green'
                        icon='ok'
                      />
                    }

                  </div>
                </div>
              </Panel.Footer>
            </Panel.Container>
          </div>
        </div>
      </div>
    )
  }

  handleInputChange = (name, value) => {
    this.setState(prevState => {
      if (name === 'fraudDataType') {
        return {
          ...prevState,
          [name]:       value,
          itemType:     value !== FRAUD_CHARGEBACK ? '' : prevState.itemType,
          rejectStatus: value !== FRAUD_INTERCHANGE_REJECT ? '' : prevState.rejectStatus
        }
      }

      return {
        ...prevState,
        [name]: value
      }
    })
  }

  handleSubmit = () => {
    const { transactionId, fraudDataType, itemType, rejectStatus } = this.state
    const data = {
      transaction_id:  transactionId,
      fraud_data_type: fraudDataType,
      item_type:       itemType,
      reject_status:   rejectStatus
    }

    this.props.saveTestData(this.props.url, data)
  }

  errorMessage() {
    if (this.props.hasError) {
      return (
        <Error>
          <ul>
            {this.props.errors.map(key => <li key={key}>{key}</li>)}
          </ul>
        </Error>
      )
    }
  }
}

TestDataForm.propTypes = {
  fraudDataTypeOptions:           PropTypes.array.isRequired,
  chargeBackItemTypeOptions:      PropTypes.array.isRequired,
  interchangeRejectStatusOptions: PropTypes.array.isRequired,
  url:                            PropTypes.string.isRequired,
  saveTestData:                   PropTypes.func.isRequired,
  resetFormState:                 PropTypes.func.isRequired,
  isSaved:                        PropTypes.bool.isRequired,
  isSaving:                       PropTypes.bool.isRequired,
  errors:                         PropTypes.array.isRequired,
  hasError:                       PropTypes.bool.isRequired
}

export default connect(
  state => ({
    errors:   state.testDataForm.errors,
    hasError: state.testDataForm.hasError,
    isSaved:  state.testDataForm.isSaved,
    isSaving: state.testDataForm.isSaving
  }),
  { saveTestData, resetFormState }
)(TestDataForm)
