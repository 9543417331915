import 'cross-fetch/polyfill';
import * as ACTION_TYPES from '@app/components/actions/action-types';

/**
 * Action Creators for Service Status
 */

export const requestServiceStatusData = () =>
  ({ type: ACTION_TYPES.SERVICE_STATUS_DATA_REQUEST });
export const receivedServiceStatusData = (data) =>
  ({ type: ACTION_TYPES.SERVICE_STATUS_DATA_RECEIVE,
     data: data });
export const failServiceStatusData = (error) =>
  ({ type: ACTION_TYPES.SERVICE_STATUS_DATA_FAIL,
     error: error });

export function fetchServiceStatusData(url) {

  return function(dispatch) {
    dispatch(requestServiceStatusData());

    return fetch(url)
      .then((response) => response.json())
      .then((json) => dispatch(receivedServiceStatusData(json)))
      .catch((error) => dispatch(failServiceStatusData(error)));
  };
}
