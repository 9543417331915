import DependentDropdowns from '@engine/features/dependent_dropdowns';

function attachDependentDropdowns() {
  $('select.reseller')
    .on('change', { url: '/reseller_merchants/' },
      DependentDropdowns.updateDependentDropdown);

  $('select.merchant')
    .on('change', { url: '/merchant_reseller/' },
      DependentDropdowns.updateDependentDropdown);

  $('select.role')
    .on('change', { url: '/affiliate_user_owner/' },
      DependentDropdowns.updateDependentDropdown);

  $('select.affiliate_owner')
    .on('change', { url: '/affiliate_user_role/' },
      DependentDropdowns.updateDependentDropdown);
}

export default { attachDependentDropdowns };
