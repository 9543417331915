/* eslint semi: ["error", "never"]*/
/* eslint arrow-parens: ["error", "as-needed"]*/
/* eslint key-spacing: ["error", { "afterColon": true, "align": "value" }] */
/* eslint no-multi-spaces: ["error", { exceptions: { "ImportDeclaration": true,
                                       "VariableDeclarator": true  } }] */

import Error                        from '@engine/components/presentational/base/error'
import PropTypes                    from 'prop-types'
import React                        from 'react'
import Report                       from '@app/components/presentational/declined-reasons-report'
import Table                        from '@engine/components/presentational/base/table'
import { connect }                  from 'react-redux'
import { fetchDeclinedReasonsData } from '@app/components/actions/creators/declined-reasons'

class DeclinedReasons extends React.Component {

  /**
   * Component life cycle
   */

  componentDidMount() {
    this.fetchData()
  }

  /**
   * Render component
   */

  render() {
    if (this.hasError) {
      return <Error>{this.errorMessage}</Error>
    }

    if (!this.reports) { return null }

    const REPORT_COLUMNS = [
      I18n.t('object.attributes.count'),
      I18n.t('object.attributes.status'),
      I18n.t('object.attributes.error_class'),
      I18n.t('object.attributes.error_message')
    ]

    return (
      <Table.Container>
        <Table.Head columns={REPORT_COLUMNS} />
        <Table.Body>
          {this.renderReports()}
        </Table.Body>
      </Table.Container>
    )
  }

  /**
   * Computed properties
   */

  get reports() {
    return this.props.declinedReasons.reports
  }

  get hasError() {
    return this.props.declinedReasons.hasError
  }

  get errorMessage() {
    return this.props.declinedReasons.error
  }

  /**
   * Methods
   */

  fetchData() {
    this.props.fetchDeclinedReasonsData(this.props.url)
  }

  renderReports() {
    return this.reports.map(report => <Report key={report.id}
      report={report}
      url={this.props.paymentTrxUrl} />)
  }
}

DeclinedReasons.propTypes = {
  fetchDeclinedReasonsData: PropTypes.func.isRequired,
  url:                      PropTypes.string.isRequired,
  paymentTrxUrl:            PropTypes.string.isRequired,
  declinedReasons:          PropTypes.object.isRequired
}

export default connect(
  state => ({ declinedReasons: state.declinedReasons }),
  { fetchDeclinedReasonsData }
)(DeclinedReasons)
