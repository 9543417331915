function itemTemplate(item) {
  const itemName = $('select.selectize-with-dropdown').attr('id');
  const itemUrl = '/' + itemName + '/' + item.value + '/edit';
  const itemHTML = [
    '<div class="item" data-value=', item.value, '><a href=', itemUrl,
    ' class="item-link ' + itemName + '-', item.value, '">', item.text, '</a></div>'
  ];

  return itemHTML.join('');
}

function applyTagSelectize() {
  $('select.selectize-with-dropdown').selectize({
    plugins: ['remove_button'],
    create: false,
    closeOnSelect: true,
    delimiter: ';',
    persist: false,
    render: {
      item: function(data) {
        return itemTemplate(data);
      }
    }
  });
}

function listenForLinkClick() {
  $('.selectize-container').on('click', 'a.item-link', function() {
    var href = $(this).attr('href');

    window.location = href;
  });
}

const SelectizeWithDropdown = { applyTagSelectize, listenForLinkClick };

export default SelectizeWithDropdown;
