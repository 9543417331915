import 'cross-fetch/polyfill';
import * as ACTION_TYPES from '@app/components/actions/action-types';

/**
 * Action Creators for Resellers Tree
 */

export const requestResellerTreeData = () =>
  ({ type: ACTION_TYPES.RESELLERS_TREE_DATA_REQUEST });
export const receivedResellerTreeData = (data) =>
  ({ type: ACTION_TYPES.RESELLERS_TREE_DATA_RECEIVE,
     data: data });
export const failResellerTreeData = (error) =>
  ({ type: ACTION_TYPES.RESELLERS_TREE_DATA_FAIL,
     error: error });

export function fetchResellerTreeData(url) {

  return function(dispatch) {
    dispatch(requestResellerTreeData());

    return fetch(url)
      .then((response) => response.json())
      .then((json) => dispatch(receivedResellerTreeData(json)))
      .catch(() => {
        dispatch(failResellerTreeData());
      });
  };
}

export const closeResellerTree = () =>
  ({ type: ACTION_TYPES.RESELLERS_TREE_CLOSE });
export const openResellerTree = () =>
  ({ type: ACTION_TYPES.RESELLERS_TREE_OPEN });
