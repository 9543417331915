/* eslint semi: ["error", "never"]*/
/* eslint arrow-parens: ["error", "as-needed"]*/
/* eslint key-spacing: ["error", { "afterColon": true, "align": "value" }] */
/* eslint no-multi-spaces: ["error", { exceptions: { "ImportDeclaration": true,
                                       "VariableDeclarator": true  } }] */

import Icon      from '@engine/components/presentational/base/icon'
import PropTypes from 'prop-types'
import React     from 'react'

class DeclinedReasonsReport extends React.Component {

  /**
   * Render component
   */

  render() {
    return (
      <tr>
        <td>{this.props.report.count}</td>
        <td>{this.props.report.status}</td>
        <td>{this.props.report.error_class}</td>
        <td>{this.props.report.error_message}</td>
        <td>
          <a href={`${this.props.url}/${this.props.report.id}`}
            title={I18n.t('javascript.features.system_status.index.show_example_transaction')}>
            <Icon icon='eye-open' />
          </a>
        </td>
      </tr>
    )
  }
}

DeclinedReasonsReport.propTypes = {
  report: PropTypes.object.isRequired,
  url:    PropTypes.string.isRequired
}

export default DeclinedReasonsReport
