/* eslint semi:            ["error", "never"]*/
/* eslint arrow-parens:    ["error", "as-needed"]*/
/* eslint key-spacing:     ["error", { "afterColon": true, "align": "value" }] */
/* eslint no-multi-spaces: ["error", { exceptions: { "ImportDeclaration": true,
                                       "VariableDeclarator": true  } }] */

import PropTypes from 'prop-types'
import React     from 'react'

const CheckboxIssuingAPI = ({ name, value, checked, onChange, labelText, identifier, disabled }) =>
  <div className='checkbox'>
    <label htmlFor={identifier}>
      <input
        type='checkbox'
        name={name}
        id={identifier}
        checked={checked}
        value={value}
        onChange={event => { onChange(event.target.checked, value) }}
        disabled={disabled} />
      <span>{labelText}</span>
    </label>
  </div>

CheckboxIssuingAPI.propTypes = {
  name:       PropTypes.string.isRequired,
  checked:    PropTypes.bool.isRequired,
  value:      PropTypes.string.isRequired,
  onChange:   PropTypes.func.isRequired,
  labelText:  PropTypes.string.isRequired,
  identifier: PropTypes.string.isRequired,
  disabled:   PropTypes.bool.isRequired
}

CheckboxIssuingAPI.defaultProps = {
  value:    false,
  disabled: false
}

export default CheckboxIssuingAPI
