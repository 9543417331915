/* eslint semi: ["error", "never"]*/
/* eslint arrow-parens: ["error", "as-needed"]*/
/* eslint key-spacing: ["error", { "afterColon": true, "align": "value" }] */
/* eslint no-multi-spaces: ["error", { exceptions: { "ImportDeclaration": true,
                                       "VariableDeclarator": true  } }] */
import PropTypes   from 'prop-types'
import React       from 'react'
import SelectField from '@engine/components/presentational/base/select-field'
import TextField   from '@engine/components/presentational/base/text-field'

const MERCHANT_TYPE_TO_REQURED_FIELDS_MAPPING = {
  INDIVIDUAL: ['representative_name', 'representative_id'],
  ENTERPRISE: ['registration_number', 'shareholder_id', 'shareholder_name']
}
const NON_REQURED_FIELDS = [
  'settlement_number', 'contact_email',
  'customer_service_number', 'customer_service_email'
]
const REQUIRED_FIELD = true

class AlipayMerchantForm extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      merchantType: this.alipayMerchantValue('merchant_type')
    }
  }

  /**
   * Render component
   */

  render() {
    return (
      <div className='row'>
        <div className='col-lg-12 col-lg-offset-3'>
          <SelectField
            name='merchant_type'
            value={this.alipayMerchantValue('merchant_type')}
            label={I18n.t('object.attributes.merchant_type')}
            options={this.props.merchantTypeOptions}
            onChange={this.onMerchantTypeChange}
            required={REQUIRED_FIELD}
          />
        </div>
        <div className='col-lg-12 col-lg-offset-3'>
          <SelectField
            name='site_information[type]'
            value={this.alipayMerchantSiteValue('type')}
            label={I18n.t('object.attributes.site_type')}
            options={this.props.siteTypeOptions}
            required={REQUIRED_FIELD}
          />
        </div>
        <div className='col-lg-12 col-lg-offset-3'>
          <TextField
            name='site_information[url]'
            value={this.alipayMerchantSiteValue('url')}
            label={I18n.t('object.attributes.site_url')}
            required={REQUIRED_FIELD}
          />
        </div>
        <div className='col-lg-12 col-lg-offset-3'>
          <TextField
            name='site_information[name]'
            value={this.alipayMerchantSiteValue('name')}
            label={I18n.t('object.attributes.site_name')}
          />
        </div>
        <div className='col-lg-12 col-lg-offset-3'>
          <TextField
            name='contact_number'
            value={this.alipayMerchantValue('contact_number')}
            label={I18n.t('object.attributes.contact_number')}
            required={REQUIRED_FIELD}
          />
        </div>
        {
          Object.values(MERCHANT_TYPE_TO_REQURED_FIELDS_MAPPING).map(
            additionaRequiredFields =>
              additionaRequiredFields.map(additionaRequiredField =>
                <div className='col-lg-12 col-lg-offset-3' key={additionaRequiredField}>
                  <TextField
                    name={additionaRequiredField}
                    value={this.alipayMerchantValue(additionaRequiredField)}
                    label={I18n.t(`object.attributes.${additionaRequiredField}`)}
                    required={this.isRequired(additionaRequiredField)}
                  />
                </div>
              )
          )
        }
        {
          NON_REQURED_FIELDS.map(field =>
            <div className='col-lg-12 col-lg-offset-3' key={field}>
              <TextField
                name={field}
                value={this.alipayMerchantValue(field)}
                label={I18n.t(`object.attributes.${field}`)}
              />
            </div>
          )
        }
      </div>
    )
  }

  isRequired(field) {
    return this.state.merchantType === ''
      ? false
      : MERCHANT_TYPE_TO_REQURED_FIELDS_MAPPING[this.state.merchantType].includes(field)
  }

  alipayMerchantValue(name) {
    var value = this.props.alipayMerchant[name]

    return value !== undefined ? value : ''
  }

  alipayMerchantSiteValue(name) {
    var siteInfo = this.props.alipayMerchant['site_information']

    return siteInfo !== undefined ? siteInfo[name] : ''
  }

  onMerchantTypeChange = selectedValue => {
    this.setState({ merchantType: selectedValue })
  }

}

AlipayMerchantForm.propTypes = {
  alipayMerchant:      PropTypes.object,
  merchantTypeOptions: PropTypes.array.isRequired,
  siteTypeOptions:     PropTypes.array.isRequired
}

AlipayMerchantForm.defaultProps = {
  alipayMerchantForm: {}
}

export default AlipayMerchantForm
