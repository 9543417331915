/* eslint semi: ["error", "never"]*/
/* eslint arrow-parens: ["error", "as-needed"]*/
/* eslint key-spacing: ["error", { "afterColon": true, "align": "value" }] */
/* eslint no-multi-spaces: ["error", { exceptions: { "ImportDeclaration": true,
                                       "VariableDeclarator": true  } }] */

import {
  TEST_DATA_FORM_REQUEST,
  TEST_DATA_FORM_REQUEST_FAIL,
  TEST_DATA_FORM_RESET,
  TEST_DATA_FORM_RESPONSE_RECEIVE
}  from '@app/components/actions/action-types'

/**
 * Initial state
 */

const INITIAL_STATE = {
  errors:   [],
  hasError: false,
  isSaved:  false,
  isSaving: false
}

/**
   * Reducer
   */

function testDataReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
  case TEST_DATA_FORM_REQUEST:
    return {
      ...state,
      hasError: false,
      isSaving: true,
      errors:   []
    }
  case TEST_DATA_FORM_RESPONSE_RECEIVE:
    return {
      ...state,
      errors:   action.data.errors,
      hasError: action.data.hasError,
      isSaved:  !action.data.hasError,
      isSaving: false
    }
  case TEST_DATA_FORM_REQUEST_FAIL:
    return {
      ...state,
      errors:   [I18n.t('javascript.errors.something_went_wrong')],
      hasError: true,
      isSaving: false
    }
  case TEST_DATA_FORM_RESET:
    return INITIAL_STATE
  default:
    return state
  }
}

export default testDataReducer

