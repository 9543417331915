import {
  SERVICE_STATUS_DATA_FAIL,
  SERVICE_STATUS_DATA_RECEIVE,
  SERVICE_STATUS_DATA_REQUEST
} from '@app/components/actions/action-types';

/**
 * Initial state
 */

const INITIAL_STATE = {
  status: false,
  hasError: false,
  error: ''
};

/**
 * Reducer
 */

function serviceStatusReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
  case SERVICE_STATUS_DATA_REQUEST:
    return Object.assign({}, state, {
      hasError: false
    });
  case SERVICE_STATUS_DATA_RECEIVE:
    return Object.assign({}, state, {
      status: action.data.status
    });
  case SERVICE_STATUS_DATA_FAIL:
    return Object.assign({}, state, {
      hasError: true,
      error: action.error.message
    });
  default:
    return state;
  }
}

export default serviceStatusReducer;
