/* eslint semi:            ["error", "never"]*/
/* eslint arrow-parens:    ["error", "as-needed"]*/
/* eslint key-spacing:     ["error", { "afterColon": true, "align": "value" }] */
/* eslint no-multi-spaces: ["error", { exceptions: { "ImportDeclaration": true,
                                       "VariableDeclarator": true  } }] */

import Checkbox      from '@app/components/presentational/issuing-api-fields/checkbox-issuing-api'
import CheckboxGroup from '@app/components/presentational/issuing-api-fields/checkbox-group'
import PropTypes     from 'prop-types'
import React         from 'react'

class IssuingAPIFields extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      supportsIssuingApi:       this.props.supportsIssuingApi,
      allowedIssuingAttributes: this.props.allowedIssuingAttributes
    }
  }

  /**
   * Render component
   */
  render() {
    return (
      <>
      <div className='heading-6 mt-20'>{I18n.t('javascript.merchants.form.issuing_api')}</div>
      <div className='form-group'>
        <div className='col-lg-12 col-xl-10 col-xl-offset-1'>
          <div className='row'>
            <div className='col-lg-12 mb-15'>
              <Checkbox
                name='features[]'
                value={'supports_issuing_api'}
                checked={this.state.supportsIssuingApi}
                onChange={this.toggleSupportsIssuingApi}
                labelText={I18n.t('javascript.merchants.form.supports_issuing_api')}
                identifier='feature_supports_issuing_api'
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-6'>
              <CheckboxGroup
                name={'providers'}
                checkboxes={this.props.providers}
                allowedAttributes={this.state.allowedIssuingAttributes['providers']}
                onChange={this.updateIssuingAttributes}
                supportsIssuingApi={this.state.supportsIssuingApi}
              />
              <CheckboxGroup
                name={'currencies'}
                checkboxes={this.props.currencies}
                allowedAttributes={this.state.allowedIssuingAttributes['currencies']}
                onChange={this.updateIssuingAttributes}
                supportsIssuingApi={this.state.supportsIssuingApi}
              />
            </div>
            <div className='col-lg-6'>
              <CheckboxGroup
                name={'card_brands'}
                checkboxes={this.props.cardBrands}
                allowedAttributes={this.state.allowedIssuingAttributes['card_brands']}
                onChange={this.updateIssuingAttributes}
                supportsIssuingApi={this.state.supportsIssuingApi}
              />
              <CheckboxGroup
                name={'card_types'}
                checkboxes={this.props.cardTypes}
                allowedAttributes={this.state.allowedIssuingAttributes['card_types']}
                onChange={this.updateIssuingAttributes}
                supportsIssuingApi={this.state.supportsIssuingApi}
              />
            </div>
          </div>
        </div>
      </div>
    </>
    )
  }

  toggleSupportsIssuingApi = () => {
    if (this.state.supportsIssuingApi) {
      this.setState({
        allowedIssuingAttributes: {
          providers:   [],
          currencies:  [],
          card_brands: [],
          card_types:  []
        },
        supportsIssuingApi: false
      })
    } else {
      this.setState({ supportsIssuingApi: true })
    }
  }

  updateIssuingAttributes = (name, checked, value) => {
    const currentAttributes = this.state.allowedIssuingAttributes[name]

    if (checked) {
      currentAttributes.push(value)
      this.setState({ allowedIssuingAttributes: { ...this.state.allowedIssuingAttributes,
                                                  [name]: currentAttributes } })

    } else {
      const filteredAttributes = currentAttributes.filter(attribute => attribute !== value)

      this.setState({ allowedIssuingAttributes: { ...this.state.allowedIssuingAttributes,
                                                  [name]: filteredAttributes } })
    }
  }
}

IssuingAPIFields.propTypes = {
  allowedIssuingAttributes: PropTypes.object.isRequired,
  supportsIssuingApi:       PropTypes.bool.isRequired,
  cardBrands:               PropTypes.array.isRequired,
  cardTypes:                PropTypes.array.isRequired,
  currencies:               PropTypes.array.isRequired,
  providers:                PropTypes.array.isRequired
}

export default IssuingAPIFields
