import AlipayMerchantForm from '@app/components/containers/alipay-merchant-form';
import DeclinedReasons from '@app/components/containers/declined-reasons';
import ExportToolbar from '@engine/components/containers/export-toolbar';
import IssuingAPIFields from '@app/components/containers/issuing-api-fields';
import IssuingLoadOperations from '@app/components/containers/issuing-load-operations';
import ProxyIframe from '@app/components/proxyIframe.js';
import ReactOnRails from 'react-on-rails';
import ResellersTree from '@app/components/containers/resellers-tree';
import ServiceStatus from '@app/components/containers/service-status';
import TestDataForm from '@app/components/containers/test-data-form';
import withProvider from '@engine/components/withProvider';

/**
 * Register & Initialize React Application Components
 */
export function initialize() {
  ReactOnRails.register({
    ProxyIframeContainer: withProvider(ProxyIframe),
    ResellersTreeBtn: withProvider(ResellersTree),
    DeclinedReasons: withProvider(DeclinedReasons),
    ServiceStatus: withProvider(ServiceStatus),
    AlipayMerchantForm: AlipayMerchantForm,
    IssuingLoadOperations: withProvider(IssuingLoadOperations),
    ExportToolbar: withProvider(ExportToolbar),
    TestDataForm: withProvider(TestDataForm),
    IssuingAPIFields: IssuingAPIFields
  });
}

const AdminConsoleReact = { initialize };

export default AdminConsoleReact;
