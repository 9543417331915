/* eslint semi:            ["error", "never"]*/
/* eslint arrow-parens:    ["error", "as-needed"]*/
/* eslint key-spacing:     ["error", { "afterColon": true, "align": "value" }] */
/* eslint no-multi-spaces: ["error", { exceptions: { "ImportDeclaration": true,
                                       "VariableDeclarator": true  } }] */

import Checkbox  from '@app/components/presentational/issuing-api-fields/checkbox-issuing-api'
import PropTypes from 'prop-types'
import React     from 'react'

const CheckboxGroup = ({
  name, supportsIssuingApi, onChange, checkboxes, allowedAttributes
}) => {
  const titleize = word => word.charAt(0).toUpperCase() + word.slice(1)

  const resolveLabel = checkbox => {
    if (name === 'card_brands' || name === 'card_types') {
      return titleize(checkbox)
    } else if (name === 'providers') {
      return I18n.t(`javascript.issuing.status.${checkbox.toLowerCase()}_provider`)
    }

    return checkbox
  }

  const resolveChecked = checkbox => allowedAttributes.includes(checkbox)

  return (
    <div className='mb-5'>
      {I18n.t(`javascript.merchants.form.allowed_issuing_attributes_${name}`)}
      {checkboxes.map(checkbox =>
        <div key={checkbox} className='ml-15'>
          <Checkbox
            name={`allowed_issuing_attributes[${name}][]`}
            value={checkbox}
            checked={resolveChecked(checkbox)}
            labelText={resolveLabel(checkbox)}
            identifier={`allowed_issuing_attributes_${checkbox.toLowerCase()}`}
            disabled={!supportsIssuingApi}
            onChange={(checked, value) => onChange(name, checked, value)}
          />
        </div>
      )}
    </div>
  )
}

CheckboxGroup.propTypes = {
  name:               PropTypes.string.isRequired,
  supportsIssuingApi: PropTypes.bool.isRequired,
  onChange:           PropTypes.func.isRequired,
  checkboxes:         PropTypes.array.isRequired,
  allowedAttributes:  PropTypes.array.isRequired
}

export default CheckboxGroup
