/* eslint semi: ["error", "never"]*/
/* eslint arrow-parens: ["error", "as-needed"]*/
/* eslint key-spacing: ["error", { "afterColon": true, "align": "value" }] */
/* eslint no-multi-spaces: ["error", { exceptions: { "ImportDeclaration": true,
                                       "VariableDeclarator": true  } }] */

import 'cross-fetch/polyfill'
import {
  TEST_DATA_FORM_REQUEST,
  TEST_DATA_FORM_REQUEST_FAIL,
  TEST_DATA_FORM_RESET,
  TEST_DATA_FORM_RESPONSE_RECEIVE
} from '@app/components/actions/action-types'
import { setFlashMessage } from '@engine/actions/creators/flash-message'

/**
 * Action Creators for Test Data
 */
export const testDataFormRequest = () =>
  ({ type: TEST_DATA_FORM_REQUEST })

export const testDataFormRequestFail = () =>
  ({ type: TEST_DATA_FORM_REQUEST_FAIL })

export const testDataFormResponseReceive = data => ({
  type: TEST_DATA_FORM_RESPONSE_RECEIVE,
  data
})

export const testDataFormReset = () =>
  ({ type: TEST_DATA_FORM_RESET })

export function saveTestData(url, data) {

  return function(dispatch) {
    dispatch(testDataFormRequest())

    return fetch(url, {
      method:  'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': window.CSRF
      },
      body: JSON.stringify({ test_fraud_data_parameters: data })
    })
      .then(response => response.json())
      .then(json => dispatch(testDataFormResponseReceive(json)))
      .catch(() => dispatch(testDataFormRequestFail()))
  }
}

export function resetFormState() {

  return function(dispatch) {
    dispatch(setFlashMessage({
      status:  'warning',
      content: I18n.t('javascript.test_data.alerts.generation_scheduled')
    }))
    dispatch(testDataFormReset())
  }
}
