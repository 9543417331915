import {
  RESELLERS_TREE_CLOSE,
  RESELLERS_TREE_DATA_FAIL,
  RESELLERS_TREE_DATA_RECEIVE,
  RESELLERS_TREE_DATA_REQUEST,
  RESELLERS_TREE_OPEN
} from '@app/components/actions/action-types';

/**
 * Initial state
 */

const INITIAL_STATE = {
  isFetching: false,
  isLoaded: false,
  isOpened: false,
  data: [],
  hasError: false
};

/**
 * Reducer
 */

function resellerTreeReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
  case RESELLERS_TREE_DATA_REQUEST:
    return Object.assign({}, state, {
      isFetching: true,
      hasError: false
    });
  case RESELLERS_TREE_DATA_RECEIVE:
    return Object.assign({}, state, {
      isFetching: false,
      isLoaded: true,
      data: action.data
    });
  case RESELLERS_TREE_DATA_FAIL:
    return Object.assign({}, state, {
      isFetching: false,
      hasError: true
    });
  case RESELLERS_TREE_OPEN:
    return Object.assign({}, state, {
      isOpened: true
    });
  case RESELLERS_TREE_CLOSE:
    return Object.assign({}, state, {
      isOpened: false
    });
  default:
    return state;
  }
}

export default resellerTreeReducer;
