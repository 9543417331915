/**
 * Action Types for Reseller Tree
 */
export const RESELLERS_TREE_CLOSE        = 'RESELLERS_TREE_CLOSE';
export const RESELLERS_TREE_DATA_FAIL    = 'RESELLERS_TREE_DATA_FAIL';
export const RESELLERS_TREE_DATA_RECEIVE = 'RESELLERS_TREE_DATA_RECEIVE';
export const RESELLERS_TREE_DATA_REQUEST = 'RESELLERS_TREE_DATA_REQUEST';
export const RESELLERS_TREE_OPEN         = 'RESELLERS_TREE_OPEN';

/**
 * Action Types for Service Status
 */

export const SERVICE_STATUS_DATA_FAIL    = 'SERVICE_STATUS_DATA_FAIL';
export const SERVICE_STATUS_DATA_RECEIVE = 'SERVICE_STATUS_DATA_RECEIVE';
export const SERVICE_STATUS_DATA_REQUEST = 'SERVICE_STATUS_DATA_REQUEST';

/**
 * Action Types for Declined Reasons
 */

export const DECLINED_REASONS_DATA_FAIL    = 'DECLINED_REASONS_DATA_FAIL';
export const DECLINED_REASONS_DATA_RECEIVE = 'DECLINED_REASONS_DATA_RECEIVE';
export const DECLINED_REASONS_DATA_REQUEST = 'DECLINED_REASONS_DATA_REQUEST';


/**
 * Action Types for Issuing Card Load operation
 */

export const ISSUING_LOAD_OPERATIONS_DATA_FAIL    = 'ISSUING_LOAD_OPERATIONS_DATA_FAIL';
export const ISSUING_LOAD_OPERATIONS_DATA_RECEIVE = 'ISSUING_LOAD_OPERATIONS_DATA_RECEIVE';
export const ISSUING_LOAD_OPERATIONS_DATA_REQUEST = 'ISSUING_LOAD_OPERATIONS_DATA_REQUEST';

/**
 * Action Types for Test Data
 */
export const TEST_DATA_FORM_REQUEST          = 'TEST_DATA_FORM_REQUEST';
export const TEST_DATA_FORM_REQUEST_FAIL     = 'TEST_DATA_FORM_REQUEST_FAIL';
export const TEST_DATA_FORM_RESPONSE_RECEIVE = 'TEST_DATA_FORM_RESPONSE_RECEIVE';
export const TEST_DATA_FORM_RESET            = 'TEST_DATA_FORM_RESET';
