import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { resetSessionTimer } from '@engine/actions/creators/session-time';

export class ProxyIframe extends React.Component {

  constructor(props) {
    super(props);

    this.iframe = React.createRef();
  }

  componentDidMount() {
    this.iframe.current.addEventListener('load', this.resetSession.bind(this));
  }

  resetSession() {
    $.ajax('/').done(this.props.resetSessionTimer);
  }

  render() {
    return (
      <iframe
        title='proxy_iframe'
        src={this.props.src}
        width='100%'
        height='1000px'
        ref={this.iframe}
      />
    );
  }
}

ProxyIframe.propTypes = {
  src: PropTypes.string.isRequired,
  resetSessionTimer: PropTypes.func.isRequired
};

export default connect(null, { resetSessionTimer })(ProxyIframe);
