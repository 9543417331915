/* eslint-disable no-magic-numbers */
import 'cross-fetch/polyfill';
import {
  ISSUING_LOAD_OPERATIONS_DATA_FAIL,
  ISSUING_LOAD_OPERATIONS_DATA_RECEIVE,
  ISSUING_LOAD_OPERATIONS_DATA_REQUEST
} from '@app/components/actions/action-types';

/**
 * Action Creators for Service Status
 */

export const requestLoadOperationsData = () => ({
  type: ISSUING_LOAD_OPERATIONS_DATA_REQUEST
});

export const receivedLoadOperationsData = (data) => ({
  type: ISSUING_LOAD_OPERATIONS_DATA_RECEIVE,
  data: data
});

export const failLoadOperationsData = (error) => ({
  type: ISSUING_LOAD_OPERATIONS_DATA_FAIL,
  error: error
});

export function fetchLoadOperationsData(url) {

  return function(dispatch) {
    dispatch(requestLoadOperationsData());

    return fetch(url)
      .then((response) => response.json())
      .then((json) => dispatch(receivedLoadOperationsData(json)))
      .catch((error) => dispatch(failLoadOperationsData(error)));
  };
}
