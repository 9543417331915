/* eslint semi: ["error", "never"]*/
/* eslint key-spacing: ["error", { "afterColon": true, "align": "value" }] */
/* eslint no-multi-spaces: ["error", { exceptions: { "ImportDeclaration": true,
                                       "VariableDeclarator": true  } }] */

import {
  closeResellerTree,
  fetchResellerTreeData,
  openResellerTree } from '@app/components/actions/creators/reseller-tree'
import Button        from '@engine/components/presentational/base/button'
import PropTypes     from 'prop-types'
import React         from 'react'
import TreeDrawer    from '@engine/components/presentational/tree-drawer'
import { connect }   from 'react-redux'

class ResellersTree extends React.Component {

  render() {
    return (
      <div>
        <Button
          onClick={this.openDrawer.bind(this)}
          size='sm' icon='random'>{this.props.buttonText}</Button>

        <TreeDrawer
          close={this.closeDrawer.bind(this)}
          data={this.resellerTree.data}
          error={this.resellerTree.hasError}
          open={this.resellerTree.isOpened}
          refresh={this.fetchData.bind(this)}
          loaded={!this.resellerTree.isFetching}
          title={this.props.buttonText}
        ></TreeDrawer>
      </div>
    )
  }

  openDrawer() {
    this.props.openResellerTree()

    if (!this.resellerTree.isLoaded) {
      this.fetchData()
    }
  }

  closeDrawer() {
    this.props.closeResellerTree()
  }

  fetchData() {
    this.props.fetchResellerTreeData(this.props.url)
  }

  get resellerTree() {
    return this.props.resellerTree
  }
}

ResellersTree.propTypes = {
  closeResellerTree:     PropTypes.func.isRequired,
  fetchResellerTreeData: PropTypes.func.isRequired,
  openResellerTree:      PropTypes.func.isRequired,
  buttonText:            PropTypes.string.isRequired,
  url:                   PropTypes.string.isRequired,
  resellerTree:          PropTypes.object.isRequired
}

export default connect(
  (state) => ({ resellerTree: state.resellerTree }),
  { fetchResellerTreeData, closeResellerTree, openResellerTree }
)(ResellersTree)
