/* eslint key-spacing: ["error", { "afterColon": true, "align": "value" }] */

import {
  ISSUING_LOAD_OPERATIONS_DATA_FAIL,
  ISSUING_LOAD_OPERATIONS_DATA_RECEIVE,
  ISSUING_LOAD_OPERATIONS_DATA_REQUEST
} from '@app/components/actions/action-types';

/**
 * Initial state
 */

export const INITIAL_STATE = {
  isFetching: false,
  isFetched:  false,
  hasError:   false,
  error:      null,
  data:       null
};

/**
 * Reducer
 */

function issuingLoadOperationsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
  case ISSUING_LOAD_OPERATIONS_DATA_REQUEST:
    return {
      ...state,
      isFetching: true,
      isFetched:  false,
      hasError:   false,
      error:      null
    };

  case ISSUING_LOAD_OPERATIONS_DATA_RECEIVE:
    return {
      ...state,
      isFetching: false,
      isFetched:  true,
      data:       action.data
    };
  case ISSUING_LOAD_OPERATIONS_DATA_FAIL:
    return {
      ...state,
      isFetching: false,
      isFetched:  true,
      hasError:   true,
      error:      I18n.t('javascript.errors.something_went_wrong')
    };
  default:
    return state;
  }
}

export default issuingLoadOperationsReducer;
