/* eslint-disable no-inline-comments */
/* eslint semi: ["error", "never"]*/

import PropTypes from 'prop-types'
import React     from 'react'


class IssuingLoadOperationsPresenter extends React.Component {
  render() {
    const { issuingLoadOperations } = this.props

    return (
      <>
        {issuingLoadOperations.items.map((item, key) =>
          <tr key={key} id={`card_load_operation_${item.id}`}>
            <td>
              {item.payment.url
                ? <a href={item.payment.url}>{item.payment.text}</a>
                : item.payment.text
              }
            </td>
            <td>
              <span className={`label label-${item.status_class}`}>
                {I18n.t(`issuing.load_operations.status_${item.status}`)}
              </span>
            </td>
            <td align='right'>{item.amount}</td>
            <td>{item.created_at}</td>
          </tr>
        )}
        {issuingLoadOperations.total_successful &&
          <tr id='card_load_operations_summary'>
            <td align='right'>{issuingLoadOperations.total_successful.text}</td>
            <td>{issuingLoadOperations.total_successful.count}</td>
            <td align='right'>{issuingLoadOperations.total_successful.amount}</td>
          </tr>
        }
      </>
    )
  }
}

IssuingLoadOperationsPresenter.propTypes = {
  issuingLoadOperations: PropTypes.object.isRequired
}

export default IssuingLoadOperationsPresenter
